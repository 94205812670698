import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';
import SEO from '../../../components/seo';

export default function RegisterJAFZA(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022 - UAE - UK Business Council' />
      <RegistrationFrom
        source='uae-uk-bc'
        page='uae-uk-bc'
        imageName='uae-uk-pc-registration'
        imageAlt='uae-uk-pc-registration'
      />
    </Layout>
  );
}
